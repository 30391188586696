
import { defineComponent, nextTick, onMounted, ref } from "vue";

import { StickyComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Order } from "@/core/model/Orders";
import moment from "moment";
import Swal from 'sweetalert2';
import ToastService from "@/core/services/ToastService";

export default defineComponent({
  name: "detalle-solicitud-credito",
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const order = ref<Order>({} as Order);
      const formRef = ref<null | HTMLFormElement>(null);
      const formData = ref({
            state_order: "",
            order: "",

      });     
    onMounted(() => {
      setCurrentPageBreadcrumbs("Solicitudes de crédito", [
        "Órdenes",
        "Detalle",
      ]);
      loadData();
      nextTick(() => {
        StickyComponent.reInitialization();
      });
    });

        //Form submit function
    const onSubmit = async () => {
        
        //await store.UpdatedTracking(formData.value);
        Swal.fire({
          title: 'Confirmación',
          text: '¿Estás seguro de actualizar la orden?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
        }).then(async (result) =>{
          if (result.value) {
            formData.value.order = order.value.uuid;
            console.log('Lo que trae el formulario: ',formData.value);
            const params = {
            order: formData.value.order,
            state_order: formData.value.state_order
          };
            try {
              await store.dispatch(Actions.ORDER_UPDATE_STATE, params);
              console.log('Se ejecutó la API correctamente');
              // Recargar la página
              //window.location.reload();
              loadData();
            } catch (error) {
              console.log('este es un error', error);
              //ToastService.show(`Order actualizada con éxito`,"success");
            }
          
          } else {
            console.log('No se ejecutó la API correctamenterr');
          }
        });
        };

    const loadData = () => {
      store.dispatch(Actions.ORDER_GET, route.params.uuid).then(() => {
        order.value = store.state.OrdersModule.order;
      });
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    return {
      order,
      formatDateTime,
      formData,
      formRef,
      onSubmit,
    };
  },
});
